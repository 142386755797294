<div class="store-details_root" [ngStyle]="{backgroundColor: state === StoreDetailsState.Scan? null : profile?.backgroundColor ?? 'var(--background-color)'}">
  <app-toolbar [title]="store?.title"
               [subtitle]="store?.subTitle"
               [image]="storeImage"
               [store]="store"
               [profile]="store?.storeProfile">
  </app-toolbar>
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="StoreDetailsState.Scan">
      <ng-container *ngIf="enableLogin && !(customerService.isSignedIn$ | async)">
        <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="scanTemplate"></ng-container>
      <ng-container *ngIf="activeAvatar">
        <ng-container *ngTemplateOutlet="avatarTemplate"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="StoreDetailsState.Browse">
      <div class="top-elements"
           [ngStyle]="{boxShadow: scrollShadow ? 'rgb(0 0 0 / 15%) 0px 3px 8px' : null, background: profile?.backgroundColor}">
        <app-search-bar *ngIf="enableProductSearch"
                        (doSearch)="store != null && doSearch(store.handle, $event)"
                        (doClear)="endSearch()"
                        hint="{{ 'SEARCH.PRODUCT.hint' | translate }}">
        </app-search-bar>
        <ng-container *ngIf="searchQuery && searchQuery.length > 0 else categoriesTemplate"></ng-container>
      </div>
      <ng-container *ngIf="searchQuery && searchQuery.length > 0 else categoriesList">
        <ng-container *ngTemplateOutlet="searchResultsTemplate"></ng-container>
      </ng-container>
      <div class="store-details-back-scanner" *ngIf="showBackToScanButton">
        <button class="store-details_back-browse"
                [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
                (click)="toggleBrowseMode()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1 21V16H3V19H6V21H1ZM18 21V19H21V16H23V21H18ZM4 18V6H6V18H4ZM7 18V6H8V18H7ZM10 18V6H12V18H10ZM13 18V6H16V18H13ZM17 18V6H18V18H17ZM19 18V6H20V18H19ZM1 8V3H6V5H3V8H1ZM21 8V5H18V3H23V8H21Z"
                  fill="currentColor"/>
          </svg>
          {{ 'STORE.backScan' |  translate}}
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #scanTemplate>
    <mat-icon class="store-details_ean-keyboard"
              (click)="showEANInputDialog()">
      keyboard
    </mat-icon>
    <mat-icon class="store-details_browse-icon"
              (click)="toggleBrowseMode()">
      dashboard
    </mat-icon>
  </ng-template>

  <ng-template #loginTemplate>
    <div class="login">
      <h4 class="login__title" (click)="openLogin()">{{ 'LOGIN.member' | translate }}<br> {{ 'LOGIN.signIn' | translate }}</h4>
    </div>
  </ng-template>

  <ng-template #avatarTemplate>
    <div class="store-details_avatar-div" *ngIf="store?.enterMessage?.message">
      <div>
        <img class="store-details_avatar-image" src="/assets/images/avatar.svg" alt="avatar" height="100px" width="100px">
      </div>
      <div class="store-details_avatar-title">{{ 'STORE.welcome' |  translate}}</div>
      <a class="store-details_avatar-text">{{store?.enterMessage?.message}}</a>
      <div style="width: 100%">
        <button class="store-details_avatar-button"
                (click)="setAvatarStorage()"
                [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}">
          {{ 'STORE.go' |  translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #categoriesTemplate>
    <ul class="categories-details"
        *ngIf="store"
        id="categoryDetails"
        [ngClass]="{'categories-details-with-search-disabled': !enableProductSearch}">
      <ng-template [ngIf]="enableCategoriesSelection">
        <li *ngFor="let category of categories">
          <div *ngIf="category.products == undefined || category.products.length > 0"
               class="other-category"
               [ngStyle]="currentSection == category.id ?
                {backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor} :
                {background: 'none', color: profile?.primaryColor}"
               [ngClass]="{'current-category': currentSection === category.id}"
               (click)="scrollToSection(category.id)">
            <div [id]="'btn_' + category.id" class="categories-details-text">
              {{ category.title }}
            </div>
          </div>
        </li>
      </ng-template>
    </ul>
  </ng-template>

  <ng-template #categoriesList>
    <div class="store-details_browse">
      <ng-container *ngIf="store else loadingStoreTemplate">
        <ul scrollSpy [spiedTags]="enableCategoriesSelection ? ['LI'] : []" (sectionChange)="onSectionChange($event)">
          <li *ngFor="let category of categories" [id]="category.id">
            <div class="store-details_category-text" *ngIf="category.products == undefined || category.products.length > 0">
              <app-category-item
                [category]="category"
                [store]="store"
                [microshop]="microshop"
              ></app-category-item>
            </div>
          </li>
        </ul>
      </ng-container>

      <ng-template #loadingStoreTemplate>
        <div class="loading-store-product-list">
          <div class="loading-store-product-background" *ngFor="let _ of [null, null, null, null, null, null]">
            <div class="loading-store-products">
              <app-product-card>
              </app-product-card>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #searchResultsTemplate>
    <ng-container *ngIf="searchHandler.isBusy && searchHandler.data.length === 0">
      <ol class="search-results">
        <li *ngFor="let empty of [null, null, null]">
          <app-product-item>
          </app-product-item>
        </li>
      </ol>
    </ng-container>
    <ng-container *ngIf="searchHandler.data.length > 0">
      <ng-container *ngIf="searchHandler.data.length > 0 else emptyResultTemplate">
        <ol class="search-results" (scroll)="onSearchScrolled($event)">
          <li class="store-details_search-result_order-line"
              *ngFor="let product of searchHandler.data">
            <app-product-item [product]="product"
                              [dropShadow]="true">
            </app-product-item>
            <mat-icon class="product-quick-purchase-button"
                      (throttleClick)="store && quickAddToCart(store.handle, product)"
                      [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}">
              add_shopping_cart
            </mat-icon>
          </li>
        </ol>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #emptyResultTemplate>
    <div class="search-results_empty">{{ 'SEARCH.empty' | translate }}</div>
  </ng-template>
</div>

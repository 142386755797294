import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[scrollSpy]',
})
export class ScrollSpyDirective {
  @Input() spiedTags: string[] = [];
  @Output() sectionChange = new EventEmitter<string>();
  private currentSection?: string;

  constructor(private el: ElementRef) {
  }

  @HostListener('body:scroll', ['$event.target'])
  onScroll(target: HTMLElement) {
    if (!this.spiedTags.length) {
      return;
    }

    let newCurrentSection: undefined | string;
    const children: HTMLElement[] = this.el.nativeElement.children;
    const scrollTop = target.scrollTop;

    for (const element of children) {
      if (this.spiedTags.some(spiedTag => spiedTag === element.tagName) && (element.offsetTop - 240) <= scrollTop) {
        newCurrentSection = element.id;
      }
    }

    if (newCurrentSection && newCurrentSection !== this.currentSection) {
      this.currentSection = newCurrentSection;
      this.sectionChange.emit(this.currentSection);
    }
  }
}
